import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import { animateScroll as aniScroll } from "react-scroll";
import Link from "../Link";
import NavItemCart from "../NavItemCart";
import Image from "../Image";
import * as Widget from "../Widget";
import * as Icon from "../Icon";
import AppConfig from "../../Config";

const {
  background,
  altBackground,
  textColor,
  textShadow,
  breakpoint
} = AppConfig["Components/Navbar"];
const height = AppConfig.global.navbarHeight;
const smallScreenHeight = AppConfig.global.smallScreenNavbarHeight;
const navItems = [
  { children: <NavItemCart />, url: "/cart" },
  { children: <Icon.Person size={24} color="#D55421" />, url: "/me" }
];

class Navbar extends React.Component {
  state = {
    openMenu: false
  };

  render() {
    let { passBreakpoint } = this.props;

    return (
      <Style.Wrapper passBreakpoint={passBreakpoint}>
        <Style.Content>
          <Link to="/" className="site-title" extraStyle={{ display: "flex" }}>
            <Image className="image" src={"../../../images/simcret-logo.png"} />
          </Link>

          {this._smallScreenNavBar()}
          {this._largeScreenNavBar()}
        </Style.Content>
      </Style.Wrapper>
    );
  }

  _smallScreenNavBar() {
    const toggleMenuState = () =>
      this.setState({ openMenu: !this.state.openMenu });
    return (
      <Style.SmallScreenWrapper breakpoint={breakpoint}>
        <Style.BuyNowButton
          onClick={() => {
            this._scrollSection("product");
          }}
        >
          Buy Now
        </Style.BuyNowButton>

        <Widget.IconButton onClick={toggleMenuState}>
          <Icon.Apps size={24} color={textColor} />
        </Widget.IconButton>

        <Style.SmallScreenMenu
          open={this.state.openMenu}
          onClick={toggleMenuState}
        >
          <div className="container">
            {[
              { children: <Icon.Home size={24} color={textColor} />, url: "/" },
              ...navItems
            ].map(({ children, url }, idx) => (
              <Link to={url} key={idx} className="nav-item">
                {children}
              </Link>
            ))}
          </div>
        </Style.SmallScreenMenu>
      </Style.SmallScreenWrapper>
    );
  }

  _largeScreenNavBar() {
    return (
      <Style.LargeScreenWrapper breakpoint={breakpoint}>
        <Style.BuyNowButton
          onClick={() => {
            this._scrollSection("product");
          }}
        >
          Buy Now
        </Style.BuyNowButton>

        {navItems.map(({ children, url }, idx) => (
          <Link to={url} key={idx} className="nav-item">
            {children}
          </Link>
        ))}
      </Style.LargeScreenWrapper>
    );
  }

  _scrollSection = id => {
    let { navActions } = this.props;
    if (!this._scrollSectionImpl(id)) {
      navActions.push("/");
      setTimeout(() => this._scrollSectionImpl(id), 600);
    }
  };

  _scrollSectionImpl = id => {
    const element = document.getElementById(id);
    if (!element) {
      return false;
    }
    const targetHeight = element.offsetTop;
    aniScroll.scrollTo(targetHeight - 50, { duration: 400 });
  };
}

const Style = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 10px 10%;
    height: ${height}px;
    z-index: 10;
    background: ${props => (props.passBreakpoint ? altBackground : background)};
    transition: background 200ms ease-in-out;

    @media screen and (max-width: 500px) {
      padding: 10px 5%;
      height: ${smallScreenHeight}px;
    }
  `,

  Content: styled.div`
    margin: 0 auto;
    max-width: 1180px;
    height: 100%;
    display: flex;
    align-items: center;

    & .site-title {
      color: ${textColor};
      text-decoration: none;
      /* font-size: 40px; */
      & .image {
        height: ${height * 0.5}px;
        @media screen and (max-width: 500px) {
          height: ${smallScreenHeight * 0.4}px;
        }
      }
    }
  `,

  LargeScreenWrapper: styled.div`
    @media screen and (max-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    & > .nav-item {
      margin-right: 15px;
      color: ${textColor};
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    & > .nav-item :last-child {
      margin-right: 0px;
    }
  `,

  SmallScreenWrapper: styled.div`
    @media screen and (min-width: ${props => props.breakpoint || 768}px) {
      display: none;
    }

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  `,

  SmallScreenMenu: styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: ${props => (props.open ? "0" : "-100vw")};
    background-color: ${props =>
      props.open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0)"};
    transition: background-color 250ms linear,
      left 0ms linear ${props => (props.open ? "0ms" : "250ms")};
    z-index: 5;

    & > .container {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding-top: 30px;

      & > .nav-item {
        color: ${textColor};
        height: 50px;
        text-decoration: none;
      }
    }
  `,
  BuyNowButton: styled.button`
    ${AppConfig["Components/OringeButton"].css || ""}
    padding-left:20px;
    padding-right: 20px;
    margin-right: 15px;

    @media screen and (max-width: 500px) {
      margin-right: 0;
    }
  `
};

export default connect(
  null,
  ActionCreator
)(Navbar);
