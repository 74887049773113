import { push, navigate } from "gatsby";
import { getStore } from "../Store";
import Actions from "../Reducers/Actions";
import ApiProxy from "../ApiProxy";
import Catchify from "../Utils/Catchify";
import API from "../Api";
import AppConfig from "../Config";

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    })
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    login: Catchify(
      async ({ username, password }) => {
        let response = await API.login({ username, password });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });

        try {
          let cartResponse = await API.getCart();
          dispatch({ type: Actions.SET_CART_DATA, payload: cartResponse });
          dispatch({
            type: Actions.SET_LOCAL_CART_DATA,
            payload: cartResponse
          });
        } catch (err) {
          console.warn("failed to get cart data!");
        }
      },

      async err => {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        throw err;
      }
    ),

    getMyProfile: Catchify(
      async () => {
        let response = await API.getMyProfile();
        dispatch({ type: Actions.AUTO_LOGIN, payload: response });
      },

      async err => {
        console.warn(err);
        throw err;
      }
    ),

    autoLogin: Catchify(
      async () => {
        let token = window.localStorage.getItem("token");
        ApiProxy.setToken(token);
        if (token) {
          let response = await API.getMyProfile();
          dispatch({ type: Actions.AUTO_LOGIN, payload: response });
          try {
            let cartResponse = await API.getCart();
            dispatch({ type: Actions.SET_CART_DATA, payload: cartResponse });
            dispatch({
              type: Actions.SET_LOCAL_CART_DATA,
              payload: cartResponse
            });
          } catch (err) {
            console.warn("failed to get cart data!");
          }
        }
      },

      async err => {
        console.warn(err);
        ApiProxy.setToken(null);
        window.localStorage.removeItem("token");
        throw err;
      }
    ),

    logout: Catchify(async () => {
      ApiProxy.setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
      dispatch({ type: Actions.LOGOUT, payload: null });
    }),

    register: async ({ username, email, password }) => {
      try {
        await API.register({ username, email, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetPassword: async ({ email }) => {
      try {
        await API.resetPassword({ email });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetValidation: async ({ username, password }) => {
      try {
        await API.resetValidation({ username, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    editMyProfile: Catchify(
      async data => {
        let payload = await API.editMyProfile(data);
        dispatch({
          type: Actions.AUTO_LOGIN,
          payload
        });
        return payload;
      },
      err => Promise.reject(err)
    ),

    fetchProducts: async ({ limit, sort, offset, search, label }) => {
      try {
        let fetching = AppConfig.data.fetchLocalProduct
          ? API.local.fetchProducts
          : API.fetchProducts;
        let results = await fetching({ limit, sort, offset, search, label });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchProductById: async id => {
      try {
        let fetching = AppConfig.data.fetchLocalProduct
          ? API.local.fetchProductById
          : API.fetchProductById;
        let result = fetching(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchStoreById: async id => {
      try {
        let result = API.fetchStoreById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchProductsByStoreId: async id => {
      try {
        let fetching = AppConfig.data.fetchLocalProduct
          ? API.local.fetchProductsByStoreId
          : API.fetchProductsByStoreId;
        let result = fetching(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchArticles: async ({ limit, sort, offset, search, label }) => {
      try {
        let results = API.fetchArticles({ limit, sort, offset, search, label });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchArticleById: async id => {
      try {
        let result = API.fetchArticleById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    getPhoneList: async () => {
      try {
        let data = await API.getPhoneList();
        //TO DO: need to support paging
        let results = { results: data };
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchSmsListById: async id => {
      try {
        let results = API.fetchSmsListById(id);
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchPhoneById: async id => {
      try {
        let results = API.fetchPhoneById(id);
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    createOrder: Catchify(
      async data => {
        let result = await API.createOrder(data);
        return result;
      },
      err => Promise.reject(err)
    ),

    editCartConfig: Catchify(
      async config => {
        let result = await API.editCartConfig(config);
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrderList: Catchify(
      async () => {
        let result = await API.getOrderList();
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrder: Catchify(
      async id => {
        let result = await API.getOrder(id);
        return result;
      },
      err => Promise.reject(err)
    ),

    getCart: async () => {
      let result = await API.getCart();
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      dispatch({
        type: Actions.SET_LOCAL_CART_DATA,
        payload: result
      });
      return result;
    },

    editLocalCartConfig: config => {
      dispatch({
        type: Actions.SET_LOCAL_CART_CONFIG_DATA,
        payload: config
      });
    },

    addItemToCart: async ({
      product_id,
      quantity,
      type,
      period,
      number,
      msg_qty
    }) => {
      let result = await API.addItemToCart({
        product_id,
        quantity,
        type,
        period,
        number,
        msg_qty
      });
      if (result["msg"]) {
        return result;
      } else {
        dispatch({
          type: Actions.SET_CART_DATA,
          payload: result
        });
      }
    },

    removeItemFromCart: async index => {
      let result = await API.removeItemFromCart(index);
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    editItemFromCart: async ({ index, config }) => {
      let result = await API.editItemFromCart({ index, config });
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    getProductList: Catchify(
      async () => {
        let result = await API.getProductList();
        return result;
      },
      err => Promise.reject(err)
    ),

    checkNumbersAvailable: async order => {
      let result = await API.checkNumbersAvailable(order);
      return result;
    },

    toUnsubscribeOrder: async order => {
      let result = await API.toUnsubscribeOrder(order);
      return result;
    }
  }
});
