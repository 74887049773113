const ApiProxy = require("../ApiProxy");
const Constants = require("../Domain/constants");
const AppConfig = require("../Config");

let productList = null;

let _fetchS3ProductList = async () => {
  await fetch(`${Constants.s3Host}/products.json`)
    .then(resp => resp.json())
    .then(data => (productList = data))
    .catch(err => (productList = []));
  return productList;
};

module.exports = {
  async fetchProducts({ limit, offset, search, label, sort }) {
    let products = productList || (await _fetchS3ProductList());
    return paging(
      products,
      { limit, offset, search, label, sort },
      AppConfig.data.localProductSearchFields
    );
  },

  async fetchProductById(id) {
    let products = productList || (await _fetchS3ProductList());
    let found = products.find(p => p.id === Number(id));
    if (!found) {
      throw Error(404);
    }
    return found;
  },

  async fetchProductsByStoreId(id) {
    let products = productList || (await _fetchS3ProductList());
    let result = products.filter(p => p.store && p.store.id === Number(id));
    return paging(result, {}, []);
  }
};

function paging(data, { limit, offset, search, label, sort }, searchFields) {
  // Paging API: (product)
  // search_fields = ('name', 'description',)
  // ordering_fields = ('created', 'updated', 'price')
  // label__icontains: label
  // store = self.request.query_params.get('store')

  let configurePagingResult = ({ data, limit, offset }) => {
    return {
      count: data.length,
      next:
        offset + limit <= data.length
          ? `https://local-api?limit=${limit}&offset=${offset + limit}`
          : null,
      previous:
        offset === 0
          ? `https://local-api?limit=${limit}&offset=${offset - limit}`
          : null,
      results: data.slice(offset, offset + limit)
    };
  };

  if (limit !== 0 && !limit && (offset !== 0 && !offset)) {
    limit = 60;
    offset = 0;
    return configurePagingResult({ data, limit, offset });
  }
  if (label) {
    data = data.filter(d => d.label && d.label.indexOf(label) > -1);
  }

  if (search) {
    let re = new RegExp(search, "i"); // ignore case
    data = data.filter(d => {
      let hit = false;
      for (let f of searchFields) {
        if (d[f].match(re)) {
          hit = true;
        }
      }
      return hit;
    });
  }

  if (sort) {
    let sortField = sort;
    if (sort.indexOf("-") === 0) {
      sortField = sortField.slice(1);
      data = data
        .slice()
        .sort((a, b) => (a[sortField] > b[sortField] ? -1 : 1));
    } else {
      sortField = sort.indexOf("+") === 0 ? sortField.slice(1) : sortField;
      data = data
        .slice()
        .sort((a, b) => (a[sortField] < b[sortField] ? -1 : 1));
    }
  }

  return configurePagingResult({ data, limit, offset });
}
