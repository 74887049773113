import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import Selectors from "../../Selectors";
import Login from "../Login";
import * as Widget from "../Widget";
import * as Icon from "../Icon";

class AddToCartButton extends Component {
  state = {
    showLoginDialog: false,
    loading: false
  };
  render() {
    let { profile, item, cartData, extraCss, redirectToCart } = this.props;
    let { showLoginDialog, loading } = this.state;
    return (
      <Wrapper extraCss={extraCss}>
        <Widget.LoadingWrapper loading={loading} spinnerSize={30}>
          <button onClick={() => this._addItemToCart()}>
            {this._renderIcon()}
            {redirectToCart ? "立即購買" : "加入購物車"}
          </button>
        </Widget.LoadingWrapper>
        {showLoginDialog && (
          <Login.Dialog
            onClose={() => this.setState({ showLoginDialog: false })}
          />
        )}
      </Wrapper>
    );
  }

  _isItemInCart = () => {
    let { cartData, item } = this.props;

    if (!cartData) {
      return false;
    }
    return !!cartData.items.find(i => i.product_id === item.id);
  };

  removeItemFromCart = () => {
    let { cartData, appActions, item } = this.props;

    let foundIdx = cartData.items.findIndex(i => i.product_id === item.id);
    this.setState({ loading: true });
    appActions
      .removeItemFromCart(foundIdx)
      .catch(err => 0)
      .then(() => this.setState({ loading: false }));
  };

  _addItemToCart = () => {
    let { appActions, navActions, profile, item, data } = this.props;
    if (!profile) {
      this.setState({ showLoginDialog: true });
      return;
    }
    this.setState({ loading: true });
    appActions
      .addItemToCart({
        product_id: item.id,
        quantity: data.quantity,
        period: data.period,
        type: data.type
      })
      .then(() => this._handleRedirect())
      .catch(err => 0)
      .then(() => this.setState({ loading: false }));
  };

  _handleRedirect = () => {
    let { navActions, redirectToCart } = this.props;
    console.log("redirectToCart", redirectToCart);
    if (redirectToCart) {
      return navActions.push("/cart");
    }
    return Promise.resolve();
  };

  _renderIcon = () => {
    let { redirectToCart } = this.props;
    const css = "width:10px; height:10px;";
    return redirectToCart ? (
      <Icon.AttachMoney color="white" css={css} />
    ) : (
      <Icon.ShoppingCart color="white" css={css} />
    );
  };
}

const Wrapper = styled.div`
  flex:1;
  & button {
    width:100%;
    ${AppConfig["Components/OringeButton"].css || ""}
    ${props => props.extraCss || ""}
  }

  & button.disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  & button:focus {
    outline: none;
  }

  /* ${AppConfig["Components/AddToCartButton"].css || ""} */
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    cartData: Selectors.getCartData(state)
  }),
  ActionCreator
)(AddToCartButton);
