const AppConfig = {
  global: {
    themeColor: "#D55421",
    navbarHeight: 70,
    smallScreenNavbarHeight: 50,
    bannerHeight: 500,
    navbarCoverContent: false,
    supportedPayType: ["ecpay"]
  },

  data: {
    fetchLocalProduct: false,
    localProductSearchFields: ["name", "description"]
  },

  "Components/Footer": {},

  "Components/Navbar": {
    background: "#FFFFFF",
    altBackground: "#FFFFFF",
    textColor: "#D55421",
    textShadow: "2px 2px #ccc",
    breakpoint: 800
  },

  "Components/Lightbox": {
    css: ``,
    thumbnailCss: `
      width: 70px;
      height: 70px;
    `
  },

  "Components/AddToCartButton": {
    css: `
    & button{
      padding: 6px 10px;
      background-color: #D55421;
      color: white;
      font-size: 12px;
      border-radius: 15px;
      text-align: center;
    }
    `
  },
  "Components/OringeButton": {
    css: `
      background-color: #D55421;
      color: white;
      padding: 6px 10px;
      font-size: 12px;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;
      &:focus {
        outline:0;
      }
      &:active{
        border:0;
      }
      &:hover{
        background-color:#de6332;
      }
    `
  },

  "Components/FilterPanel": {
    breakPointTablet: 1024
  },

  "Component/SortingGroup": {
    color: "gray",
    articlesOptions: [
      { label: "發表日期", value: "created" },
      { label: "更新日期(新 → 舊)", value: "-updated" },
      { label: "更新日期(舊 → 新)", value: "+updated" }
    ],
    productsOptions: [
      { label: "上市日期", value: "created" },
      { label: "優惠價(高 → 低)", value: "-price" },
      { label: "優惠價(低 → 高)", value: "+price" },
      { label: "更新日期(新 → 舊)", value: "-updated" },
      { label: "更新日期(舊 → 新)", value: "+updated" }
    ],
    css: ``
  },

  "Component/SortOption": {
    color: "gray",
    css: ``
  },

  "Component/AreaItem": {
    colorGold: "#D9FF15"
  },

  "Templates/LandingPage": {
    css: `

    `
  },

  "Templates/StoreDetailPage": {
    css: `
    `
  },

  "Templates/OrderDetailPage": {
    css: ``
  },

  "Templates/CartPage": {
    css: ``
  },

  "Templates/ProductListingPage": {
    layoutType: "grid",
    itemLimit: 2,
    breadcrumbs: true,
    css: `
      flex-direction: row;
      & > .products-content {
      }
    `
  },

  "Templates/ArticleListingPage": {
    layoutType: "list",
    itemLimit: 4,
    breadcrumbs: true,
    css: ``
  },

  "Components/ProductGridItem": {
    showAveragePriceWhenBuyXGetYFree: true,
    themeColor: "brown",
    stars: 5
  }
};

module.exports = AppConfig;
