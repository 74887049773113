import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";
import * as Widget from "./Widget2";

class NavItemCart extends Component {
  render() {
    let { profile, totalItemsInCart } = this.props;

    return (
      <Widget.Row justify="center" align="center">
        <Icon.ShoppingCart size={24} color="#D55421" />
        <Label>{profile ? totalItemsInCart : 0}</Label>
      </Widget.Row>
    );
  }
}

const Label = styled.span`
  margin-left: 10px;
  font-size: 14px;
  color: #f74600;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    totalItemsInCart:
      (Selectors.getCartData(state) &&
        Selectors.getCartData(state).items.length) ||
      0
  }),
  ActionCreator
)(NavItemCart);
