import React from "react";
import styled from "styled-components";
import AppConfig from "../Config";
import * as Icon from "./Icon";
import {
  CircularProgress,
  RaisedButton,
  FlatButton,
  IconButton,
  DropDownMenu,
  MenuItem,
  RadioButton,
  Checkbox
} from "material-ui";

export const BgImage = styled.div`
  background-color: ${props => props.color || "#d0d0d0"};
  background-image: url(${props => props.image});
  background-size: ${props => props.size || "cover"};
  background-repeat: ${props => props.repeat || "no-repeat"};
  background-position: ${props => props.position || "center"};
  width: 100%;
  height: 100%;
  ${props => props.extraCss};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.extraCss};
  ${props => props.css};
`;

export const MaxWidth = styled.div`
  max-width: ${props => props.width}px;
  margin: ${props => props.margin || "0 auto"};
  ${props => props.extraCss};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  ${props => props.extraCss};
  ${props => props.css};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || "stretch"};
  justify-content: ${props => props.justify || "flex-start"};
  ${props => props.extraCss};
  ${props => props.css};
`;

export const Padding = styled.div`
  display: flex;
  flex: 1;
  ${props => props.css};
`;

export const Container = styled.div`
  ${props => props.css};
`;

export const BaseButton = styled.button`
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  ${props => props.extraStyle};
`;

export const Input = styled.input`
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
  height: 40px;
  outline: none;
  color: #575757;
  border: 1px solid #dadada;

  &:focus {
    outline: 1px solid ${AppConfig["global"].themeColor};
    outline-offset: -1px;
  }

  ${props => props.extraCss};
`;

export const FixRatioImage = ({
  image,
  width = "100%",
  heightRatio = "56.25%",
  extraStyle
}) => (
  //heightRatio: (9: 16 = 56.25%) (3 : 4 = 75%)
  <div style={{ width: width, ...extraStyle }}>
    <div style={{ position: "relative", top: 0, paddingTop: heightRatio }}>
      <BgImage image={image} extraCss="position: absolute; top: 0px;" />
    </div>
  </div>
);

export const MuiSelect = props => {
  return (
    <DropDownMenu
      {...props}
      onChange={(event, index, value) => {
        // to fit in unstated form usage
        console.log(value);
        props.onChange({ ...event, target: { value: value } });
      }}
    >
      {props.children}
    </DropDownMenu>
  );
};

export class QuantityInput extends React.Component {
  render() {
    let { value, onChange, extraCss, disabled = false } = this.props;

    return (
      <QuantityInput.Wrapper extraCss={extraCss}>
        <QuantityInput.Select
          disabled={disabled}
          value={value}
          onChange={onChange}
        >
          {Array.apply(null, { length: 20 }).map((n, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </QuantityInput.Select>
        <div>
          <Icon.ArrowDownward color="#808080" size={18} />
        </div>
      </QuantityInput.Wrapper>
    );
  }

  static Select = styled.select`
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    color: ${props => (props.disabled ? "#adadad" : "#343434")};
    font-size: 14px;
    outline: none;
    padding-right: 24px;
    text-align-last: ${props => props.align || "center"};
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    cursor: pointer;
  `;

  static Wrapper = styled.div`
    width: 81px;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    ${props => props.extraCss};

    & > div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e9e9e9;
      height: 100%;
      pointer-events: none;
      right: 0;
    }
  `;
}
export class SelectInput extends React.Component {
  // TODO: move selectors to a component
  render() {
    let {
      value,
      onChange,
      extraCss,
      disabled = false,
      ext_msg = false,
      data
    } = this.props;

    return (
      <SelectInput.Wrapper extraCss={extraCss}>
        <SelectInput.Select
          disabled={disabled}
          value={value}
          onChange={onChange}
        >
          {ext_msg ? (
            <>
              <option value="min">{data.ext_msg_min}則</option>
              <option value="med">{data.ext_msg_med}則</option>
              <option value="max">{data.ext_msg_max}則</option>
            </>
          ) : (
            <>
              <option value="3m">90天</option>
              <option value="6m">180天</option>
              <option value="1y">365天</option>
            </>
          )}
        </SelectInput.Select>
        <div>
          <Icon.ArrowDownward color="#808080" size={18} />
        </div>
      </SelectInput.Wrapper>
    );
  }

  static Select = styled.select`
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    color: ${props => (props.disabled ? "#adadad" : "#343434")};
    font-size: 14px;
    outline: none;
    padding-right: 24px;
    text-align-last: ${props => props.align || "center"};
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    cursor: pointer;
  `;

  static Wrapper = styled.div`
    width: 81px;
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    ${props => props.extraCss};

    & > div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #e9e9e9;
      height: 100%;
      pointer-events: none;
      right: 0;
    }
  `;
}

export const LoadingWrapper = (() => {
  const Wrapper = styled.div`
    position: relative;
    & > .loading-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: rgba(169, 169, 169, 0.3);
      z-index: 1;
    }
    ${props => props.css}
  `;
  return props => {
    let { children, loading = false, spinnerSize = 36, css = "" } = props;
    return (
      <Wrapper css={css}>
        {loading && (
          <div className="loading-container">
            <CircularProgress size={spinnerSize} color={"white"} />
          </div>
        )}
        {children}
      </Wrapper>
    );
  };
})();

export const SubmitButton = (() => {
  const Wrapper = styled.div`
    position: relative;
    & > .loading-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: rgba(169, 169, 169, 0.3);
      z-index: 1;
    }

    & > button {
      width: 160px;
      height: 40px;
      border: 0;
      font-size: 14px;
      color: ${AppConfig["global"].themeColor};
      background-color: #fff;
      cursor: pointer;
      outline: 0;

      &:disabled {
        background-color: #a9a9a9;
        color: #fff;
        opacity: 0.9;
        cursor: not-allowed;
      }
    }
  `;
  return props => {
    let { text, onClick, loading = false, disabled = false } = props;
    return (
      <Wrapper>
        {loading && (
          <div className="loading-container">
            <CircularProgress size={36} color={"white"} />
          </div>
        )}
        <button onClick={onClick} disabled={disabled}>
          {text}
        </button>
      </Wrapper>
    );
  };
})();

export const CheckboxField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  cursor: pointer;
  ${props => props.extraCss};

  & > .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > .label-text {
    font-size: 14px;
    color: #575757;
    margin-left: 6px;
  }

  & > .airport-label-text {
    font-size: 14px;
    color: #575757;
    margin-left: 6px;
    flex: 1;
  }

  & .icons {
    margin-left: 20px;
    max-width: 200px;

    & > .credit-card {
      width: 50px;
    }
  }

  & .single-icon-container {
    margin-left: 20px;
    min-width: 180px;
  }

  & .cvs {
    width: 35px;

    @media screen and (max-width: 375px) {
      width: 40px;
    }

    @media screen and (max-width: 360px) {
      width: 35px;
    }
  }
`;

export const Spinner = CircularProgress;
export const Button = RaisedButton;
export { FlatButton, IconButton, MenuItem, RadioButton, Checkbox };
