const ApiProxy = require("../ApiProxy");
const constants = require("../Domain/constants");

const SlsHost = constants.slsApi.host;

module.exports = {
  async getCart() {
    return ApiProxy.get({
      path: `${SlsHost}/cart?token=${ApiProxy.apiToken}`,
      withHost: true
    });
  },

  async editCartConfig(config) {
    return ApiProxy.put({
      path: `${SlsHost}/cart?token=${ApiProxy.apiToken}`,
      withHost: true,
      data: { config }
    });
  },

  async addItemToCart({ product, quantity }) {
    return ApiProxy.post({
      path: `${SlsHost}/cart/add?token=${ApiProxy.apiToken}`,
      data: { product, quantity },
      withHost: true
    });
  },

  async removeItemFromCart(index) {
    return ApiProxy.post({
      path: `${SlsHost}/cart/remove?token=${ApiProxy.apiToken}`,
      data: { index },
      withHost: true
    });
  },

  async editItemFromCart({ index, config: { product, quantity } }) {
    return ApiProxy.post({
      path: `${SlsHost}/cart/edit?token=${ApiProxy.apiToken}`,
      data: { index, config: { product, quantity } },
      withHost: true
    });
  }
};
