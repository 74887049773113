import React, { Component } from "react";
import styled from "styled-components";
import PathPreFix from "../../Utils/PathPrefixUtil";
import Link from "../Link";
import AppConfig from "../../Config";
import * as Icon from "../Icon";
import Image from "../Image";

const {
  global: { themeColor }
} = AppConfig;

class Footer extends Component {
  render() {
    let { extraStyle = "", pathname } = this.props;

    if (
      pathname === "/cart/" ||
      pathname === "/checkout/" ||
      pathname === "/order/"
    ) {
      return null;
    }

    return (
      <Wrapper extraStyle={extraStyle}>
        <Info>
          <Content>
            <Image
              src="../images/simcret-logo-white.png"
              css="width:120px; margin-right: 20px"
            />
          </Content>
          <Content main>
            <div>聯絡我們</div>
            <div>
              <Icon.Email color="white" size={16} />
              <span className="contact">cs@simcret.com</span>
            </div>
          </Content>

          <Content>
            <div className="row-link">
              <div>
                <Link
                  to="/privacy_policy"
                  extraStyle={{
                    marginRight: 30,
                    textDecoration: "none",
                    color: "white"
                  }}
                >
                  隱私權政策
                </Link>
              </div>
              <div>
                <Link
                  to="/terms_of_service"
                  extraStyle={{
                    marginRight: 30,
                    textDecoration: "none",
                    color: "white"
                  }}
                >
                  使用者條款
                </Link>
              </div>
            </div>
            <div className="copyright">
              Copyright © 2019 SIMcret Limited. All rights reserved.
            </div>
            {/* <div className="menu">
              <div>
                <Link
                  to="/"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Home
                </Link>
              </div>
              <div>
                <Link
                  to="/paging"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Paging
                </Link>
              </div>
              <div>
                <Link
                  to="/products"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Product
                </Link>
              </div>
              <div>
                <Link
                  to="/checkout"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Checkout
                </Link>
              </div>
              <div>
                <Link
                  to="/cart"
                  extraStyle={{
                    marginTop: 20,
                    textDecoration: "none",
                    color: "#505050"
                  }}
                >
                  Cart
                </Link>
              </div>
            </div>
             */}
          </Content>
        </Info>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: ${themeColor};
  width: 100vw;
  min-height: 110px;
  padding: 20px 0;

  color: white;
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    padding: 30px 30px;
  }
  ${props => props.extraStyle};
`;

let Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }

  & .copyright {
    font-size: 0.6em;
  }
`;

let Content = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: ${props => (props.main ? 1 : 0)};
  margin: 10px 0;

  & .contact {
    font-size: 0.6em;
    margin: 0 10px;
  }

  & .row-link {
    display: flex;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export default Footer;
