import React, { Component } from "react";
import styled from "styled-components";
import AddToCartButton from "../../Components/AddToCartButton";

const breakpoint = 500;

class ProductListItem extends Component {
  state = {
    data: {
      period: "3m",
      quantity: 1,
      type: "new"
    }
  };
  render() {
    let { product } = this.props;
    let { data } = this.state;
    return (
      <Wrapper>
        <div className="container">
          <div className="title-bg"> </div>
          <div className="wider-2">
            <div className="header">國碼國家</div>
            <div className="body">
              <p style={{ fontWeight: "bold" }}>
                + {product.country_code} {product.name}
              </p>
            </div>
          </div>
          <div className="wider-3">
            <div className="header">描述</div>
            <div className="body">
              <p style={{ color: "#828282", whiteSpace: "pre-wrap" }}>
                {product.description}
              </p>
            </div>
          </div>
          <div>
            <div className="header">週期</div>
            <div className="body">
              <select
                value={this.state.period}
                onChange={() =>
                  this.setState({
                    data: { ...data, period: event.target.value }
                  })
                }
              >
                {" "}
                {/* wait for api */}
                <option value="3m">90天</option>
                <option value="6m">180天</option>
                <option value="1y">365天</option>
              </select>
            </div>
          </div>
          <div>
            <div className="header">數量</div>
            <div className="body">
              <select
                value={this.state.quantity}
                onChange={() =>
                  this.setState({
                    data: { ...data, quantity: event.target.value }
                  })
                }
              >
                {" "}
                {/* wait for api */}
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>
          <div>
            <div className="header">價格</div>
            <div className="body">
              <OriginPrice>
                {product[`price_${data.period}`] * data.quantity}
              </OriginPrice>
              <Price>{product[`price_${data.period}`] * data.quantity}</Price>
            </div>
          </div>
          <div className="wider-2">
            <div className="header reserve"> </div>
            <div className="body button-section">
              <AddToCartButton
                item={product}
                data={data}
                extraCss="max-width:150px"
              />
              <AddToCartButton
                item={product}
                data={data}
                redirectToCart
                extraCss="max-width:150px"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  background: white;
  margin-bottom: 10px;
  overflow: scroll;
  font-size: 0.8em;

  & .container {
    position: relative;
    display: flex;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      z-index: 1;
      padding: 1% 2%;

      &.wider-2 {
        flex-grow: 2;
      }
      &.wider-3 {
        flex-grow: 3;
      }
      & .header {
        flex: 0 0 33px;
        color: white;
      }
      & .body {
        flex: 3 1;
      }
      & .reserve {
        white-space: pre;
      }
    }
    & > .title-bg {
      position: absolute;
      top: 0;
      left: 0;
      flex: 0;
      z-index: 0;

      width: 100%;
      height: 33px;
      background-color: #d55421;
    }
    @media screen and (max-width: ${breakpoint}px) {
      flex-direction: column;
      padding: 3% 2%;
      & > div {
        flex-direction: row;
        padding: 2% 3%;
        border-bottom: solid 0.5px #9a9a9a;
        & .header {
          color: #9a9a9a;
          flex-basis: 20%;
        }

        & .reserve {
          flex-basis: auto;
        }
        & .button-section {
          display: flex;
          flex-direction: row;
        }
      }
      & > div:first-child,
      div:last-child {
        border: 0;
      }
      & > .title-bg {
        height: 10px;
      }
    }
  }
  ${props => props.css}
`;

const OriginPrice = styled.div`
  text-decoration: line-through;
  font-weight: 400;
  font-size: 0.6em;
  color: #bfbfbf;
  ${props => props.css};
`;

const Price = styled.div`
  color: #f74600;
`;

export default ProductListItem;
