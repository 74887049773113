const ApiProxy = require("../ApiProxy");

module.exports = {
  async bootstrap() {
    return ApiProxy.get({
      path: "/api/bootstrap/"
    });
  },

  async login({ username, password }) {
    return ApiProxy.post({
      path: "/api/user/login/",
      data: { username, password }
    });
  },

  async getProfile(id) {
    return ApiProxy.get({
      path: `/api/user/profile/${id}/`
    });
  },

  async getMyProfile() {
    return ApiProxy.get({
      path: `/api/user/profile/`
    });
  },

  async editMyProfile(data) {
    let formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    return ApiProxy.formPut({
      path: "/api/user/profile/",
      formData
    });
  },

  async register({ username, email, password }) {
    return ApiProxy.post({
      path: "/api/user/register/",
      data: { username, email, password }
    });
  },

  async resetValidation({ username, password }) {
    return ApiProxy.post({
      path: "/api/users/reset_validation/",
      data: {
        username,
        password
      }
    });
  },

  async changePassword({ username, password, newPassword }) {
    return ApiProxy.post({
      path: `/api/user/change_password/`,
      data: {
        username,
        password,
        new_password: newPassword
      }
    });
  },

  async resetPassword({ email }) {
    return ApiProxy.post({
      path: `/api/users/reset_password/`,
      data: {
        email
      }
    });
  },

  async fetchStores() {
    return ApiProxy.get({
      path: `/api/store/?no_page=1`
    });
  },

  async fetchProducts({ limit, offset, search, label, sort }) {
    return ApiProxy.get({
      path: `/api/product?limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ""
      }${label ? `&label=${label}` : ""}${sort ? `&ordering=${sort}` : ""}`
    });
  },

  async fetchProductById(id) {
    return ApiProxy.get({
      path: `/api/product/${id}`
    });
  },

  async fetchStoreById(id) {
    return ApiProxy.get({
      path: `/api/store/${id}`
    });
  },

  async fetchProductsByStoreId(id) {
    return ApiProxy.get({
      path: `/api/product/?store=${id}`
    });
  },

  async fetchArticleById(id) {
    return ApiProxy.get({
      path: `/api/article/${id}`
    });
  },

  async fetchArticles({ limit, offset, search, label, sort }) {
    return ApiProxy.get({
      path: `/api/article?limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ""
      }${label ? `&label=${label}` : ""}${sort ? `&ordering=${sort}` : ""}`
    });
  },

  async fetchItemById(id) {
    return ApiProxy.get({
      path: `/api/items/${id}/`
    });
  },

  async fetchItemsByCategory(id) {
    return ApiProxy.get({
      path: `/api/categories/${id}/items/`
    });
  },

  async fetchItemComments(id) {
    return ApiProxy.get({
      path: `/api/items/${id}/comments/`
    });
  },

  async createItemComment({ item, text }) {
    return ApiProxy.post({
      path: `/api/items/${item.id}/comments/`,
      data: {
        text
      }
    });
  },

  async editItemComment({ item, itemComment, text }) {
    return ApiProxy.put({
      path: `/api/items/${item.id}/comments/${itemComment.id}/`,
      data: {
        text
      }
    });
  },

  async deleteItemComment({ item, itemComment }) {
    return ApiProxy.delete({
      path: `/api/items/${item.id}/comments/${itemComment.id}/`
    });
  },

  async createItemCommentResponse({ item, comment, text }) {
    return ApiProxy.post({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/`,
      data: {
        text
      }
    });
  },

  async editItemCommentResponse({ item, comment, response, text }) {
    return ApiProxy.put({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/${response.id}/`,
      data: {
        text
      }
    });
  },

  async deleteItemCommentResponse({ item, comment, response }) {
    return ApiProxy.delete({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/${response.id}/`
    });
  },

  async createOrder({
    currency,
    paymentType,
    title,
    is_subscribe_order,
    exec_times
  }) {
    return ApiProxy.post({
      secure: true,
      path: `/checkout/order/`,
      data: {
        title,
        currency,
        payment_type: paymentType,
        is_subscribe_order,
        exec_times
      }
    });
  },

  async getOrderList() {
    return ApiProxy.get({
      secure: true,
      path: `/checkout/order/`
    });
  },

  async getOrder(id) {
    return ApiProxy.get({
      secure: true,
      path: `/checkout/order/${id}/`
    });
  },

  async getPhoneList() {
    return ApiProxy.get({
      secure: true,
      path: `/api/phone/`
    });
  },

  async fetchPhoneById(id) {
    return ApiProxy.get({
      path: `/api/phone/${id}`
    });
  },

  async fetchSmsListById(id) {
    return ApiProxy.get({
      secure: true,
      path: `/api/sms/?phone=${id}`
    });
  },

  // admin
  async getMyStore() {
    return ApiProxy.get({
      secure: true,
      path: `/api/store/my/`
    });
  },

  async editStore(id, data) {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return ApiProxy.formPut({
      path: `/api/store/${id}/`,
      formData
    });
  },

  async deleteStoreImagesById(id) {
    return ApiProxy.delete({
      secure: true,
      path: `/api/store_images/${id}/`
    });
  },

  async createStoreImages(id, data) {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return ApiProxy.formPost({
      secure: true,
      path: `/api/store/${id}/images/`,
      formData
    });
  },

  async deleteProductImagesById(id) {
    return ApiProxy.delete({
      secure: true,
      path: `/api/product_images/${id}/`
    });
  },

  async createProductImages(id, data) {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return ApiProxy.formPost({
      secure: true,
      path: `/api/product/${id}/images/`,
      formData
    });
  },

  async getCart() {
    return ApiProxy.get({
      secure: true,
      path: `/api/cart/`
    });
  },

  async editCartConfig(config) {
    return ApiProxy.post({
      secure: true,
      path: `/api/cart/edit_config/`,
      data: config
    });
  },

  async removeItemFromCart(index) {
    return ApiProxy.post({
      secure: true,
      path: `/api/cart/delete/`,
      data: { index }
    });
  },

  async addItemToCart({ product_id, quantity, type, period, number, msg_qty }) {
    return ApiProxy.post({
      secure: true,
      path: `/api/cart/add/`,
      data: { product_id, quantity, type, period, number, msg_qty }
    });
  },

  async editItemFromCart({ index, config: { quantity, period, msg_qty } }) {
    return ApiProxy.post({
      secure: true,
      path: `/api/cart/edit_item/`,
      data: {
        index,
        quantity,
        period,
        msg_qty
      }
    });
  },

  async getProductList() {
    return ApiProxy.get({
      secure: true,
      path: `/api/product/`
    });
  },

  async checkNumbersAvailable(order) {
    return ApiProxy.post({
      secure: true,
      path: `/api/check_number_available/?order=${order}`
    });
  },

  async toUnsubscribeOrder(order) {
    return ApiProxy.post({
      secure: true,
      path: `/api/rtn_app/`,
      data: {
        order: order
      }
    });
  }
};
