import React, { Component } from "react";
import Dialog from "../Dialog";
import { connect } from "react-redux";
import styled from "styled-components";
import Image from "../Image";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import * as Unstated from "../../Utils/Unstated";
import * as Widget from "../Widget";
import * as Icon from "../Icon";
import * as L from "../../Utils/Lang";
import { FacebookLoginButton, GoogleLoginButton } from "../SocialLoginButtons";
import * as Svg from "./SvgItems";
import Config from "../../Config";

const moduleConfig = Config["Components/Login"] || {
  svgColor: "orange",
  buttonColor: "orange"
};
// moduleConfig.svgColor

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > .form {
    padding: 50px;
    @media screen and (max-width: 950px) {
      width: 100%;
    }

    & > h2 {
      margin-bottom: 40px;
      text-align: left;
      color: #444;
    }

    & > p {
      font-size: 14px;
      margin-bottom: 100px;
    }
  }

  & > .image {
    @media screen and (max-width: 950px) {
      display: none;
    }
  }
`;

const SocialLoginSection = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 380px) {
    flex-direction: column;
  }
`;

const UiState = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  RESET_PASSWORD: "RESET_PASSWORD",
  VALIDATE_ACCOUNT: "VALIDATE_ACCOUNT"
};

class LoginDialog extends Component {
  state = {
    uiState: UiState.LOGIN
  };

  render() {
    let { uiState } = this.state;

    switch (uiState) {
      case UiState.LOGIN:
        return this._renderLogin();
      case UiState.REGISTER:
        return this._renderRegister();
      case UiState.RESET_PASSWORD:
        return this._renderResetPassword();
      case UiState.VALIDATE_ACCOUNT:
        return this._renderValidateAccount();
      default:
        return null;
    }
  }

  _renderLogin = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <Unstated.Form
          key="login"
          init={() => ({
            values: { username: "", password: "" },
            editing: true
          })}
          submit={values => {
            if (!values.username || !values.password) {
              return Promise.resolve({
                values,
                error: L.s("all-fields-required")
              });
            }

            let { username, password } = values;
            return appActions
              .login({ username, password })
              .then(onClose)
              .then(() => {
                // appActions.ga.trackingUserAction("User", "login", "success")
              })
              .catch(err => {
                if (err.status === 401) {
                  //appActions.ga.trackingUserAction("User", "login", "fail 401");
                  return { error: L.s("error-login-message") };
                }
                if (err.status === 491) {
                  // appActions.ga.trackingUserAction("User", "login", "fail 491");
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password
                  });
                }
                // appActions.ga.trackingUserAction(
                //   "User",
                //   "login",
                //   "fail others"
                // );
                return { error: L.s("error-message") };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <Wrapper>
              <Icon.Close
                onClick={onClose}
                color="black"
                style={{ position: "absolute", right: 25, top: 25, width: 25 }}
              />
              <Image
                className="image"
                background
                src={"../../../images/simcret-logo.png"}
                css={"width: 30%;height:300px; background-size:contain;"}
              />
              <div className="form">
                <h2
                  style={{
                    fontSize: 35,
                    marginBottom: 50,
                    textAlign: "center",
                    color: "#444"
                  }}
                >
                  登入
                </h2>

                <Widget.MaxWidth width="400">
                  <SocialLoginSection align="center">
                    <FacebookLoginButton />
                    <GoogleLoginButton />
                  </SocialLoginSection>

                  <Widget.Row align="center">
                    <div
                      style={{
                        flexGrow: 1,
                        borderTop: "2px solid #eee"
                      }}
                    />
                    <p style={{ padding: 10, color: "#000000" }}>或</p>
                    <div
                      style={{
                        flexGrow: 1,
                        borderTop: "2px solid #eee"
                      }}
                    />
                  </Widget.Row>

                  <Widget.Row
                    extraCss="position: relative;margin-bottom:10px;"
                    align="center"
                  >
                    <Icon.Email
                      color="#ccc"
                      style={{ position: "absolute", left: 10, width: 25 }}
                    />
                    <Widget.Input
                      placeholder={L.s("email")}
                      {...inputProps.username}
                      extraCss="padding: 10px; padding-left: 40px; border: 0px; border-bottom: 1px solid #989898;"
                    />
                  </Widget.Row>

                  <Widget.Row extraCss="position: relative;" align="center">
                    <Icon.Lock
                      color="#ccc"
                      style={{ position: "absolute", left: 10, width: 25 }}
                    />
                    <Widget.Input
                      placeholder={L.s("password")}
                      type="password"
                      {...inputProps.password}
                      onKeyPress={event => {
                        let code = event.which || event.keyCode;
                        if (code === 13) {
                          submitProps.submit();
                        }
                      }}
                      extraCss="padding: 10px;padding-left: 40px; border: 0px; border-bottom: 1px solid #989898;"
                    />
                  </Widget.Row>

                  <div style={{ marginTop: 10 }}>
                    {submitProps.submitError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: 5
                        }}
                      >
                        {submitProps.submitError}
                      </div>
                    )}
                    {submitProps.submitting ? (
                      <Widget.Center>
                        <Widget.Spinner />
                      </Widget.Center>
                    ) : (
                      <Widget.Row justify="center">
                        <Widget.Button
                          label={L.s("forget-password")}
                          labelStyle={{
                            color: moduleConfig.buttonColor,
                            padding: 0
                          }}
                          style={{ height: 40, width: 300 }}
                          onClick={() =>
                            this.setState({ uiState: UiState.RESET_PASSWORD })
                          }
                        />
                        <Widget.Button
                          label={L.s("login")}
                          labelStyle={{ color: "white" }}
                          onClick={submitProps.submit}
                          disabled={
                            !submitProps.enabled || submitProps.submitting
                          }
                          backgroundColor={moduleConfig.buttonColor}
                          style={{ height: 40, width: 300 }}
                        />
                      </Widget.Row>
                    )}
                  </div>

                  <Widget.Center
                    extraCss={`
                                            margin-top: 20px;
                                            padding: 10px;
                                            border-top: 2px solid #eee;
                                            font-size: 14px;
                                        `}
                  >
                    <p>
                      {L.s("not-member")}{" "}
                      <span
                        style={{
                          color: moduleConfig.buttonColor,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          this.setState({ uiState: UiState.REGISTER })
                        }
                      >
                        {L.s("register")}
                      </span>
                    </p>
                  </Widget.Center>
                </Widget.MaxWidth>
              </div>
            </Wrapper>
          )}
        </Unstated.Form>
      </Dialog>
    );
  };

  _renderRegister = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <Unstated.Form
          key="register"
          init={() => ({
            values: {
              email: "",
              password: ""
            },
            editing: true
          })}
          submit={values => {
            if (!values.email || !values.password) {
              return Promise.resolve({
                values,
                error: L.s("all-fields-required")
              });
            }

            let { email, password } = values;
            let username = email;
            return appActions
              .register({ username, email, password })
              .then(() => appActions.login({ username, password }))
              .then(onClose)
              .catch(err => {
                if (err.status === 491) {
                  return this.setState({
                    uiState: UiState.VALIDATE_ACCOUNT,
                    username,
                    password
                  });
                }
                if (err.status === 492) {
                  return { error: L.s("email-used") };
                }
                if (err.status === 493) {
                  return { error: L.s("email-used") };
                }
                return { error: L.s("error-register-message") };
              });
          }}
        >
          {({ values, errors, inputProps, editProps, submitProps }) => (
            <Wrapper>
              <Icon.Close
                onClick={onClose}
                color="black"
                style={{ position: "absolute", right: 25, top: 25, width: 25 }}
              />
              <div className="image">
                <Svg.Hi color={moduleConfig.svgColor} />
              </div>
              <div className="form">
                <h2>註冊新帳號</h2>

                <p>
                  註冊後會寄送認證信至您的電子信箱。
                  <br />
                  點擊該連結後才算成功註冊喔！
                </p>

                <Widget.MaxWidth width="400">
                  <Widget.Row
                    extraCss="position: relative;margin-bottom:10px;"
                    align="center"
                  >
                    <Icon.Email
                      color="#ccc"
                      style={{ position: "absolute", left: 10, width: 25 }}
                    />
                    <Widget.Input
                      placeholder={L.s("email")}
                      {...inputProps.email}
                      extraCss="padding: 10px;padding-left: 40px; border: 0px; border-bottom: 1px solid #989898;"
                    />
                  </Widget.Row>

                  <Widget.Row extraCss="position: relative;" align="center">
                    <Icon.Lock
                      color="#ccc"
                      style={{ position: "absolute", left: 10, width: 25 }}
                    />
                    <Widget.Input
                      placeholder={L.s("password")}
                      type="password"
                      {...inputProps.password}
                      extraCss="padding: 10px;padding-left: 40px; border: 0px; border-bottom: 1px solid #989898;"
                    />
                  </Widget.Row>

                  <div style={{ marginTop: 20 }}>
                    {submitProps.submitError && (
                      <div
                        style={{
                          color: "red",
                          textAlign: "center",
                          marginBottom: 5
                        }}
                      >
                        {submitProps.submitError}
                      </div>
                    )}
                    {submitProps.submitting ? (
                      <Widget.Center>
                        <Widget.Spinner />
                      </Widget.Center>
                    ) : (
                      <Widget.Row justify="center">
                        <Widget.Button
                          label={L.s("register")}
                          labelStyle={{ color: "white" }}
                          onClick={submitProps.submit}
                          disabled={
                            !submitProps.enabled || submitProps.submitting
                          }
                          backgroundColor={moduleConfig.buttonColor}
                          style={{ height: 40, width: 300 }}
                        />
                      </Widget.Row>
                    )}
                  </div>

                  <Widget.Center
                    extraCss={`
                                            margin-top: 20px;
                                            padding: 10px;
                                            border-top: 2px solid #eee;
                                            font-size: 14px;
                                        `}
                  >
                    <p>
                      {L.s("already-member")}{" "}
                      <span
                        style={{
                          color: moduleConfig.buttonColor,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          this.setState({ uiState: UiState.LOGIN })
                        }
                      >
                        {L.s("login")}
                      </span>
                    </p>
                  </Widget.Center>
                </Widget.MaxWidth>
              </div>
            </Wrapper>
          )}
        </Unstated.Form>
      </Dialog>
    );
  };

  _renderResetPassword = () => {
    let { onClose, appActions } = this.props;

    return (
      <Dialog>
        <Wrapper>
          <Icon.Close
            onClick={onClose}
            color="black"
            style={{ position: "absolute", right: 25, top: 25, width: 25 }}
          />
          <div className="image">
            <Svg.Lock color={moduleConfig.svgColor} />
          </div>
          <div className="form">
            <h2>忘記密碼?</h2>

            <p>請輸入您的電子信箱，我們將會寄送重設密碼的連結至您的信箱。</p>

            <Unstated.WithState state={{ resetPasswordSuccess: false }}>
              {({ state, setState }) => {
                let { resetPasswordSuccess } = state;

                if (resetPasswordSuccess === true) {
                  return (
                    <Widget.MaxWidth width="400">
                      <p>{L.s("reset-password-success-message")}</p>

                      <Widget.Center
                        extraCss={`
                                                    margin-top: 20px;
                                                    padding: 10px;
                                                    border-top: 2px solid #eee;
                                                    font-size: 14px;
                                                `}
                      >
                        <p>
                          {L.s("back-to")}{" "}
                          <span
                            style={{
                              color: moduleConfig.buttonColor,
                              cursor: "pointer"
                            }}
                            onClick={() =>
                              this.setState({ uiState: UiState.LOGIN })
                            }
                          >
                            {L.s("login")}
                          </span>
                        </p>
                      </Widget.Center>
                    </Widget.MaxWidth>
                  );
                }

                return (
                  <Unstated.Form
                    key="reset-password"
                    init={() => ({
                      values: { email: "" },
                      editing: true
                    })}
                    submit={values => {
                      if (!values.email) {
                        return Promise.resolve({
                          values,
                          error: L.s("all-fields-required")
                        });
                      }

                      let { email } = values;
                      return appActions
                        .resetPassword({ email })
                        .then(() => setState({ resetPasswordSuccess: true }))
                        .catch(err => {
                          if (err.status === 404) {
                            return { error: L.s("email-not-found") };
                          }
                          return { error: L.s("error-reset-password-message") };
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      inputProps,
                      editProps,
                      submitProps
                    }) => (
                      <Widget.MaxWidth width="400">
                        <Widget.Row
                          extraCss="position: relative;"
                          align="center"
                        >
                          <Icon.Email
                            color="#ccc"
                            style={{
                              position: "absolute",
                              left: 10,
                              width: 25
                            }}
                          />
                          <Widget.Input
                            placeholder={L.s("email")}
                            {...inputProps.email}
                            extraCss="padding: 10px;padding-left: 40px; border: 0px; border-bottom: 1px solid #989898;"
                          />
                        </Widget.Row>

                        <div style={{ marginTop: 20 }}>
                          {submitProps.submitError && (
                            <div
                              style={{
                                color: "red",
                                textAlign: "center",
                                marginBottom: 5
                              }}
                            >
                              {submitProps.submitError}
                            </div>
                          )}
                          {submitProps.submitting ? (
                            <Widget.Center>
                              <Widget.Spinner />
                            </Widget.Center>
                          ) : (
                            <Widget.Row justify="center">
                              <Widget.Button
                                label={L.s("next-step")}
                                labelStyle={{ color: "white" }}
                                onClick={submitProps.submit}
                                disabled={
                                  !submitProps.enabled || submitProps.submitting
                                }
                                backgroundColor={moduleConfig.buttonColor}
                                style={{ height: 40, width: 300 }}
                              />
                            </Widget.Row>
                          )}
                        </div>

                        <Widget.Center
                          extraCss={`
                                                            margin-top: 20px;
                                                            padding: 10px;
                                                            border-top: 2px solid #eee;
                                                            font-size: 14px;
                                                        `}
                        >
                          <p>
                            {L.s("back-to")}{" "}
                            <span
                              style={{
                                color: moduleConfig.buttonColor,
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                this.setState({ uiState: UiState.LOGIN })
                              }
                            >
                              {L.s("login")}
                            </span>
                          </p>
                        </Widget.Center>
                      </Widget.MaxWidth>
                    )}
                  </Unstated.Form>
                );
              }}
            </Unstated.WithState>
          </div>
        </Wrapper>
      </Dialog>
    );
  };

  _renderValidateAccount = () => {
    let { onClose, appActions } = this.props;
    let { username, password } = this.state;

    return (
      <Dialog>
        <Wrapper>
          <Icon.Close
            onClick={onClose}
            color="black"
            style={{ position: "absolute", right: 25, top: 25, width: 25 }}
          />
          <div className="image">
            <Svg.Mail color={moduleConfig.svgColor} />
          </div>
          <div className="form">
            <h2>請驗證信箱</h2>

            <Unstated.WithState
              state={{
                resetValidationSuccess: false,
                submitting: false,
                errMsg: ""
              }}
            >
              {({ state, setState }) => {
                let { resetValidationSuccess, submitting, errMsg } = state;

                function _submit() {
                  setState({ submitting: true });
                  appActions
                    .resetValidation({ username, password })
                    .then(() =>
                      setState({
                        resetValidationSuccess: true,
                        submitting: false
                      })
                    )
                    .catch(() =>
                      setState({
                        submitting: false,
                        errMsg: L.s("err-reset-validation-message")
                      })
                    );
                }

                return (
                  <Widget.MaxWidth width="400">
                    {(() => {
                      if (resetValidationSuccess === true) {
                        return <p>{L.s("reset-validation-success-message")}</p>;
                      }

                      return (
                        <p
                          style={{
                            fontSize: 14,
                            marginBottom: 100
                          }}
                        >
                          {L.s("reset-validation-message")}
                        </p>
                      );
                    })()}
                    <div style={{ marginTop: 20 }}>
                      {errMsg && (
                        <div
                          style={{
                            color: "red",
                            textAlign: "center",
                            marginBottom: 5
                          }}
                        >
                          {errMsg}
                        </div>
                      )}
                      {submitting ? (
                        <Widget.Center>
                          <Widget.Spinner />
                        </Widget.Center>
                      ) : (
                        <Widget.Row justify="center">
                          <Widget.Button
                            label={L.s("reset-validation")}
                            labelStyle={{ color: "white" }}
                            onClick={_submit}
                            disabled={submitting}
                            backgroundColor={moduleConfig.buttonColor}
                            style={{ height: 40, width: 300 }}
                          />
                        </Widget.Row>
                      )}
                    </div>

                    <Widget.Center
                      extraCss={`
                                                margin-top: 20px;
                                                padding: 10px;
                                                border-top: 2px solid #eee;
                                                font-size: 14px;
                                            `}
                    >
                      <p>
                        {L.s("back-to")}{" "}
                        <span
                          style={{
                            color: moduleConfig.buttonColor,
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            this.setState({ uiState: UiState.LOGIN })
                          }
                        >
                          {L.s("login")}
                        </span>
                      </p>
                    </Widget.Center>
                  </Widget.MaxWidth>
                );
              }}
            </Unstated.WithState>
          </div>
        </Wrapper>
      </Dialog>
    );
  };
}

LoginDialog = connect(
  null,
  ActionCreator
)(LoginDialog);

const Login = ({ renderLogin, onClick }) => {
  if (!renderLogin) {
    return <Widget.Button label={L.s("login")} onClick={onClick} />;
  }

  return renderLogin({ onClick });
};

class LoginButton extends Component {
  render() {
    let { profile, renderLogin } = this.props;

    return (
      <Unstated.Toggle>
        {({ enable, setEnable }) => (
          <div>
            {enable && <LoginDialog onClose={() => setEnable(false)} />}
            {profile ? (
              <Widget.Button label={L.s("logout")} onClick={this._logout} />
            ) : (
              <Login
                onClick={() => setEnable(true)}
                renderLogin={renderLogin}
              />
            )}
          </div>
        )}
      </Unstated.Toggle>
    );
  }

  _logout = () => {
    let { appActions } = this.props;
    appActions.logout();
  };
}

LoginButton = connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(LoginButton);

export default {
  Dialog: LoginDialog,
  Button: LoginButton
};
