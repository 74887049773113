import { combineReducers } from "redux";
import user from "./User";
import lang from "./Lang";
import product from "./Product";
import cart from "./Cart";
import localCart from "./LocalCart";
import store from "./Store";

export default combineReducers({
  user,
  lang,
  product,
  cart,
  localCart,
  store
});
